<template>
  <div>
    <!-- Suche -->
    <v-card v-if="item === null">
      <v-toolbar color="#424242" dark dense flat>
        <v-toolbar-title>{{ $t('erp.lang_nav_waresearch') }}</v-toolbar-title>
      </v-toolbar>

      <v-card-text style="height: 200px; padding: 0;">
        <!-- Search Input -->
        <v-container style="margin-top: 30px;">
          <v-layout>
            <v-flex md12>
              <v-autocomplete
                  v-model="selectedItem"
                  :loading="loading"
                  :items="showItems"
                  :filter="searchFilter"
                  item-text="name"
                  item-value="id"
                  return-object
                  @keyup="searchItem($event,item)"
                  cache-items
                  clearable
                  class="mx-3"
                  flat
                  hide-no-data
                  hide-details
                  :label="$t('erp.lang_posItemName') + ', EAN'"
                  solo-inverted
              >
                <template v-slot:selection="data">
                  {{ data.item.name }}
                </template>
                <template v-slot:item="data">
                  <template v-if="!data.item.isVariation">
                    <v-list-item-content v-text="data.item.name"></v-list-item-content>
                  </template>
                  <template v-else>
                    <v-list-item-content>
                      <v-list-item-title v-text="data.item.name"></v-list-item-title>
                      <v-list-item-subtitle>
                        <span v-if="data.item.variationSize.length > 0">{{ $t('generic.lang_size') }}: {{ data.item.variationSize }}</span> <br>
                        <span v-if="data.item.variationColor.length > 0">{{ $t('erp.lang_warecreate_color') }}: {{ data.item.variationColor }}</span>
                      </v-list-item-subtitle>
                    </v-list-item-content>
                  </template>
                </template>
              </v-autocomplete>
            </v-flex>
          </v-layout>
        </v-container>

        <div v-if="item !== null && !loading2">
          <h2>{{ item.name }}</h2>
        </div>
      </v-card-text>
    </v-card>

    <!-- Editor -->
    <ItemRecipesEditor v-if="item !== null" :item="item" @goBack="goBack"></ItemRecipesEditor>
  </div>
</template>

<script>
import ItemRecipesEditor from "./ItemRecipesEditor";
import {mapState} from "vuex";
import {ENDPOINTS} from "../../../../config";

export default {
  name: "ItemRecipes",

  components: {
    ItemRecipesEditor
  },

  data() {
    return {
      item: null,
      searchInput: "",
      loading: false,
      loading2: false,
      showItems: [],
      selectedItem: null
    }
  },

  watch: {
    selectedItem(val) {
      if (val !== null) {
        this.item = val;
      }
    }
  },

  methods: {
    searchItem(event) {
      clearTimeout(this.awaitingSearch);
      this.awaitingSearch = setTimeout(() => {
        this.showItems = [];
        this.loading=false;
        this.axios.post(ENDPOINTS.ERP.ITEM.SEARCH2, {query: event.target.value})
            .then(res => {
              this.showItems = res.data;
            }).catch(err => {

        }).finally(() => {
          this.loading = false;
        })
      }, 500);
    },
    goBack() {
      this.selectedItem = null;
      this.searchInput = "";
      this.item = null;
    },
    //THIS FUNCTION IS FOR AUTOCOMPLETE SEARCH QUERY HIGHLIGHTING
    searchFilter(item, queryText, itemText) {
      return (itemText.toLocaleLowerCase().indexOf(queryText.toLocaleLowerCase()) > -1) || item.ean == queryText;
    },
    async getItemInfo(itemID) {
      this.loading2 = true;

      this.item = this.$store.dispatch("items/getItemByID", itemID);

      this.loading2 = false;
    },
  },
}
</script>