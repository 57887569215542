<template>
    <v-card>
        <v-card-title>{{ $t('erp.lang_nav_recip') }}</v-card-title>
        <v-card-subtitle>{{ item.name }}</v-card-subtitle>

        <v-card-text v-if="!loading">
            <quill-editor v-model="text"></quill-editor>
        </v-card-text>

        <v-card-text v-if="loading" class="text-center">
            <v-progress-circular indeterminate color="primary"></v-progress-circular>
        </v-card-text>

        <v-card-actions v-if="!loading">
            <v-spacer></v-spacer>
            <v-btn color="primary" :disabled="loadingSave" @click="$emit('goBack')" text>{{ $t('generic.lang_back') }}</v-btn>
            <v-btn color="success" :disabled="loadingSave" :loading="loadingSave" @click="saveRecipe">{{ $t('generic.lang_save') }}</v-btn>
        </v-card-actions>
    </v-card>
</template>

<script>
    import 'quill/dist/quill.core.css'
    import 'quill/dist/quill.snow.css'
    import 'quill/dist/quill.bubble.css'
    import { quillEditor } from 'vue-quill-editor';

    import Quill from 'quill';
    import {ImageDrop} from 'quill-image-drop-module';
    Quill.register('modules/imageDrop', ImageDrop);

    import {ENDPOINTS} from "../../../../config";

    export default {
        name: "ItemRecipesEditor",

        props: {
            item: Object
        },

        components: {
            quillEditor
        },

        data() {
            return {
                text: "",
                loading: false,
                loadingSave: false
            }
        },

        mounted() {
            this.loading = true;

            this.axios.post(ENDPOINTS.ERP.ITEM.RECIPE.GET, {
                itemID: this.item.id
            }).then((res) => {
                if(res.data.success) {
                    let recipeHTML = res.data.ware.recipeHTML;

                    if(recipeHTML !== null)
                        this.text = recipeHTML;
                }

                this.loading = false;
            }).catch(() => {
                this.loading = false;
            })
        },

        methods: {
            showImagePrompt(command) {
                const src = prompt('Enter the url of your image here')
                if (src !== null) {
                    command({ src })
                }
            },
            saveRecipe() {
                this.loadingSave = true;

                this.axios.post(ENDPOINTS.ERP.ITEM.RECIPE.UPDATE, {
                    itemID: this.item.id,
                    recipeHTML: this.text
                }).then((res) => {
                    if(res.data.success) {
                        this.$emit('goBack');
                    }

                    this.loading = false;
                }).catch(() => {
                    this.loading = false;
                })
            }
        }
    }
</script>