<template>
    <div>
        <PageTitle :heading="$t('erp.lang_instructions') " :icon="icon" :subheading="$t('erp.lang_instructions')"/>
        <div class="app-main__inner">
            <ItemRecipes></ItemRecipes>
        </div>
    </div>
</template>

<script>
    import ItemRecipes from "../../../components/erp/baseData/itemrecipes/ItemRecipes";
    import PageTitle from "../../../Layout/Components/PageTitle";

    export default {
        components: {
            PageTitle,
            ItemRecipes
        },
      data(){
          return{
            icon:'pe-7s-notebook icon-gradient bg-tempting-azure'
          }
      }
    }
</script>